import { AlertButton, alertController, AlertOptions } from '@ionic/core';

import { translate } from './translate';

export async function presentUnknownErrorAlert(
  err: any,
  handler?: AlertButton['handler']
) {
  console.error(err);
  return presentKnownErrorAlert({
    header: translate('ERROR_UNKOWN'),
    message: translate('ERROR_UNKOWN_DESC'),
    handler,
  });
}

export async function presentKnownErrorAlert(params: {
  header: string;
  message: string;
  handler?: AlertButton['handler'];
}) {
  const { header, message, handler } = params;
  const options: AlertOptions = {
    header,
    message,
    buttons: [
      {
        text: translate('OKAY'),
        handler,
      },
    ],
  };
  const alert = await alertController.create(options);
  return alert.present();
}
